import React, { useState, useEffect } from 'react';
import axios from 'axios';

function LotteryResults() {
  const [nextPeriod, setNextPeriod] = useState(null);
  const [results, setResults] = useState({
    firstPrize: 'XXXXXX',
    front3Digits: ['XXX', 'XXX'],
    last3Digits: ['XXX', 'XXX'],
    last2Digits: 'XX',
  });

  useEffect(() => {
    // ดึงข้อมูลงวดถัดไปจาก server
    axios.get('/latestPeriod')
      .then(response => {
        const perioddate = new Date(response.data.perioddate);
        const today = new Date();

        if (today >= perioddate) {
          // ถ้ายังไม่ถึงงวดถัดไป ให้ดึงข้อมูลงวดล่าสุดมาแสดง
          axios.get('/latestPeriodResults')
            .then(resultResponse => {
              const latestResults = resultResponse.data;
              setNextPeriod(perioddate);
              setResults({
                firstPrize: latestResults['1ST_6NUM'],
                front3Digits: [latestResults['FRONT_3NUM_1'], latestResults['FRONT_3NUM_2']],
                last3Digits: [latestResults['BACK_3NUM_1'], latestResults['BACK_3NUM_2']],
                last2Digits: latestResults['2NUM'],
              });
            });
        }
      });
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 after-award">
          <div className="award-current-box">
            <div className="row row1">
              <div className="col-12 col-md-6 col-lg-8">
                <h1>ผลการออกรางวัลสลากกินแบ่งรัฐบาล</h1>
                {nextPeriod && (
                  <h2>งวดประจำวันที่ {nextPeriod.getDate()} {nextPeriod.toLocaleString('th-TH', { month: 'long', year: 'numeric' })}</h2>
                )}
              </div>
            </div>
            <div className="row row2">
              <div className="col-12 d-flex flex-column flex-md-row">
                <div className="award1">
                  <h2 className="hilight">รางวัลที่ 1</h2>
                  <div className="award1-item">
                    <p className="award1-item-sub">{results.firstPrize}</p>
                  </div>
                </div>
                <div className="award2 d-flex flex-row flex-md-column">
                  <h2 className="label">รางวัลเลขหน้า 3 ตัว</h2>
                  <div className="award2-item d-flex flex-row">
                    <p className="award2-item-sub">{results.front3Digits[0]}</p>
                    <p className="award2-item-sub">{results.front3Digits[1]}</p>
                  </div>
                </div>
                <div className="award2 d-flex flex-row flex-md-column">
                  <h2 className="label">รางวัลเลขท้าย 3 ตัว</h2>
                  <div className="award2-item d-flex flex-row">
                    <p className="award2-item-sub">{results.last3Digits[0]}</p>
                    <p className="award2-item-sub">{results.last3Digits[1]}</p>
                  </div>
                </div>
                <div className="award3 d-flex flex-row flex-md-column">
                  <h2 className="label">รางวัลเลขท้าย 2 ตัว</h2>
                  <div className="award3-item">
                    <p className="award3-item-sub">{results.last2Digits}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LotteryResults;
